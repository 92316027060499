body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-draw-toolbar .leaflet-draw-edit-edit {
  display: none !important;
}

.leaflet-draw-o-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #f0f2f5;
}

.site-layout {
  margin-left: 70px;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.2 !important;
  background: #fff !important;
}

.ant-tabs-nav-operations {
  display: none !important;
}

#copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  border: 2px solid #2196F3;
  width: -webkit-fill-available;
}

.dashboard-tab .ant-tabs-nav-wrap {
  background: white !important;
  box-shadow: 0px 1px 2px #00000029;
}

.dashboard-tab .ant-tabs-content-holder {
  margin-left: 20px !important;
}

.dashboard-tab .ant-tabs-content {
  max-width: 98% !important;
}

.dashboard-tab .ant-tabs-nav-list {
  margin-left: 25px !important;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}

.edit-tag {
  user-select: none;
}

.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}