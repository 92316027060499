.App {
  text-align: center;
}

.configEndbtn {
  position: absolute !important;
  right: 0 !important;
  top: -62px !important;
  background-color: #0092ff !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.site-layout {
  /* margin-left: 70px !important; */
  margin-left: 0 !important;
  /* margin-left: 239px !important; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-blue {
  color: blue !important;
}

.text-green {
  color: #098d00 !important;
}

.text-red {
  color: red;
}

.text-gray {
  color: gray;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.inputlabel {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #444;
}

.customalertform .ant-form-item-explain {
  margin-bottom: 20px !important;
}

.topbtnalign {
  margin-top: 10px !important;
  padding: 6px 15px !important;
  height: 36px !important;
}

.drawertopbtnalign {
  margin-top: 25px !important;
  padding: 4px 15px !important;
  height: 36px !important;
}

.ant-drawer-header {
  color: #fff !important;
  background: #2196f3 !important;
}

.ant-drawer-title {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.site-page-header {
  height: 60px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  margin-bottom: 20px !important;
}

.ant-page-header {}

.ant-page-header-heading-title {
  font-size: 24px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.08 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #444 !important;
  height: 60px;
}

.ant-table-tbody>tr>td {
  color: #424242;
  font-weight: 600;
}

.ant-table-thead>tr>th {

  background: #f3f4f8 !important;
  color: #204f9d !important;
  height: 22px !important;
  padding: 10px 16px !important;
  font-size: 16px !important;

}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #2196f3 !important;
  transition: all 0.2s;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #2196f3 !important;
}

.addschedule {
  width: 87px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #444;
  position: relative;
  /* top: -3px; */
}

.addschedules {
  width: 87px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #444;
  position: relative;
  top: 4px;
  left: 4px;
}

.ant-layout-header {
  /* margin-left: 10px !important; */
}

.ant-layout-header {
  border-bottom: 2px solid #ececec;
}

.headertitle {
  font-size: 1.2em;
  font-weight: 500;
  padding-top: 10px;
  margin-bottom: 0px !important;
  color: #2196f3;
  line-height: 1.2;
}

.powered {
  font-size: 0.9em;
  font-weight: 500;
  padding-top: 10px;
  color: #d81860;
}

.mainlabel {
  padding-bottom: 8px;
  margin-bottom: 0 !important;
  font-weight: 600;
}

.headerlogo {
  float: left;
}

.headerlogo img {
  top: 21px;
  position: relative;
}

.menulogo {
  padding-left: 24px;
  padding-top: 15px;
}

.site-layout-background1 {
  margin-left: 0 !important;
}

.manualPagination {
  margin-top: 25px !important;
  margin-bottom: 10px !important;
}

.manualPagination .ant-pagination-next,
.manualPagination .ant-pagination-prev {
  line-height: 13px !important;
}

.ant-layout-header {
  height: 64px;
  padding: 0 25px !important;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}

.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 17px !important;
}

.ant-menu {
  margin: 40px 0px !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #424242 !important;
  /* font-weight: 600 !important; */
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  /* color: #424242 !important; */
  /* font-weight: 600 !important; */

}

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #2196f3 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtmp {
  margin: 0px 0px;
}

.rtmp1 {
  margin: 20px 0px;
}

.mtop {
  margin-top: 10px;
}

.matchcard .ant-card {
  background: #f5f5f5 !important;
  box-shadow: 0px 0 2px 0 rgba(0, 0, 0, 0.16) !important;
  border-radius: 8px;
  margin-bottom: 20px !important;
}

.htside span,
.rtside span {
  padding-right: 5px;
}

.htside .teamname,
.rtside .teamname {
  color: #666;
  font-weight: 600;
}

.infolabel {
  color: #2196f3;
  font-size: 16px;
  font-weight: 600;
}

.divider {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 10px 0 !important;
  border-top: 2px solid rgba(0, 0, 0, 0.06) !important;
}

.matchsummary {
  color: #444;
  margin-bottom: 0px !important;
  font-weight: 600;
  font-size: 14px;
}

.ant-form-item-label>label {
  font-weight: 600 !important;
}

.dividertext {
  margin: 10px 0px !important;
}

.flight img {
  position: relative;
  top: 8px;
  float: right;
  left: 255px;
}

.roadteam {
  position: relative;
  top: 8px;
  float: right;
  left: 255px;
}

.dividertext1 .ant-divider-with-text-left::after {
  top: -6% !important;
}

.winlabel {
  color: #3a953a !important;
}

.losslabel {
  color: red !important;
}

.teamname {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.addside {

  float: right;
  position: relative;
  /* top: -27px; */
  left: 530px;
}

.addsides {
  /* position: relative;
  top: -36px;
  left: 530px; */
  position: absolute;
  top: 4px;
  right: 5px;
}

.addbet {
  width: 87px;
  height: 22px;
  margin: 0 0 0 -7px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #444;
  position: relative;
  top: 3px
}

.addsidebet {
  float: left;
  position: relative;
  top: -5px;
  left: 530px;
}

.loading-overlay {
  display: none;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
}

.loading-overlay.is-active {
  display: flex;
}

.code {
  font-family: monospace;
  /*   font-size: .9em; */
  color: #dd4a68;
  background-color: rgb(238, 238, 238);
  padding: 0 3px;
}

.ant-select-item-group {
  color: #424242 !important;
  font-size: 16px !important;
  cursor: default;
  font-weight: 600 !important;
  text-decoration: underline;
  text-transform: uppercase;
}

.version {
  float: right
}

.todaydate {
  float: right;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 20px;
}

.site-card-border-less-wrapper .ant-page-header {
  padding: 0px !important;
}

.site-card-border-less-wrapper .ant-page-header {
  margin-bottom: 11px !important;
  border-bottom: 2px solid #2196f3;
}

.site-card-border-less-wrapper .ant-card-body {
  padding: 14px !important;
}

.scheduled {
  color: #2196f3;
  text-transform: uppercase;
  font-weight: 600;
}

.sent {
  color: #4caf50;
  text-transform: uppercase;
  font-weight: 600;
}

.admintitle {
  color: #2196f3;
  font-weight: 600;
}

.inforight {
  float: right;
  position: relative;
}

.inforight span {
  display: inline-block;
}

.headermenu {
  float: right;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #fbfbfb;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #096dd9 !important;
  border-left: 4px solid #fafafa;
}

.sidermenu-submenu .ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: transparent !important;
  box-shadow: none !important
}

.sidermenu-submenu .ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-popup {
  background: transparent !important;
}

.ant-tabs-tab {
  font-weight: 600 !important;
  font-size: 15px !important;
}

.rightspace {
  float: right;
  position: relative;
  /* right: 20px; */
  right: 0;
  /* margin-bottom: 10px; */
  margin-bottom: 15px;
}

.matchcard .ant-collapse {

  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
}

.ant-modal-confirm-content {
  padding: 0px !important;
  margin: 4px !important;
}

.ant-tag-geekblue {
  /* color: #1d39c4;
  background: #f0f5ff; */
  border-color: #adc6ff;
  background: #f3f4f8 !important;
  color: #121316 !important;
}

.checkFieldsModal {
  width: 100% !important;
  position: absolute !important;
  top: 8px !important;
  bottom: 8px !important;
  left: 8px !important;
  right: 10px !important;
  /* min-height: 50vh; */
  /* margin: 10px auto !important; */
  /* margin-left: 7% !important; */
}

/* .checkFieldsModal-playoff {
  min-height: 609px !important;
} */

.checkFieldsModal-playoff .ant-modal-content {
  min-height: 609px !important;
}

.checkFieldsModal .anticon-exclamation-circle {
  display: none !important;
}

.checkFieldsModal .ant-modal-confirm-title {
  /* margin: 25px 0 15px 0 !important; */
  font-size: 20px !important;
  text-align: center;
}

.checkFieldsModal .ant-input[disabled] {
  background-color: #fff !important;
  color: #000 !important;
}

.checkFieldsModal-odds .editbet .ant-input[disabled] {
  color: rgba(0, 0, 0, .25) !important;
  background-color: #f5f5f5 !important;
}

.checkFieldsModal .cellGroupSpan {
  color: #135da4;
}

.checkFieldsModal .ant-page-header {
  padding: 0 !important;
  margin: 0 !important;
}

.checkFieldsModal .site-page-header {
  height: 52px !important;
}

.checkFieldsModal .ant-input-group-addon {
  display: none !important;
}

.checkFieldsModal .ant-modal-footer {
  text-align: center !important;
}

.checkFieldsModal .ant-tabs-tab-btn,
.checkFieldsModal .ant-tabs-nav {
  display: none !important;
}

.checkFieldsModal-odds .edittab .ant-tabs-tab-btn,
.checkFieldsModal-odds .edittab .ant-tabs-nav {
  display: block !important;
}

.checkFieldsModal-playoff .stageTabs .ant-tabs-tab-btn,
.checkFieldsModal-playoff .stageTabs .ant-tabs-nav {
  display: block !important;
}

.checkFieldsModal .ant-modal-title {
  visibility: hidden !important;
}

.checkFieldsModal .backbtn {
  position: absolute;
  top: -52px;
  left: -6px;
  cursor: pointer;
}

.checkFieldsModal .ant-modal-close-x {
  line-height: 47px !important;
}

.checkFieldsModal .ant-modal-close-icon {
  padding: 4px;
  border-radius: 24px;
}

.checkFieldsModal .ant-modal-close-icon:hover {
  background: red !important;
}

.checkFieldsModal .ant-modal-close-icon:hover svg {
  color: #fff !important;
}

.checkFieldsModal .ant-modal-close-x svg {
  color: red !important;
}

.checkFieldsModal .ant-modal-body {
  padding: 14px 24px !important;
}

.checkFieldsModal .savebtn {
  position: absolute !important;
  top: -58px !important;
  right: 32px !important;
}

.checkFieldsModalRoot .savebtn {
  position: absolute !important;
  top: 12px !important;
  right: 57px !important;
}

.checkFieldsModalRoot .popuptitle {
    top: 17px !important;
    left: 20px !important;
}

.checkFieldsModal .updatematchbtn {
  position: absolute !important;
  top: -58px !important;
  right: 0 !important;
}

.checkFieldsModal-odds .updatematchbtn {
  position: fixed !important;
  top: 19px !important;
  right: 46px !important;
  /* top: -58px !important; */
  /* right: 0 !important; */
}

.checkFieldsModal .tablefilter {
  position: fixed;
  top: 19px;
  right: 187px;
}

.checkFieldsModal .filterdivider {
  /* position: fixed;
  background-color: red;
  border-color: red;
  height: 30px; */
}

.checkFieldsModal .ant-modal-footer {
  display: none !important;
}

.checkFieldsModal .popuptitle {
  margin: 0;
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  position: absolute;
  top: -52px;
}

.checkFieldsModal .popuptitle1 {
  left: -6px;
}

.checkFieldsModal .popuptitle2 {
  left: 30px;
}

.checkFieldsModal .popuptitle span {
  color: #0092ff;
}

.checkFieldsModal-odds .screendiv {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 84vh !important;
  max-height: 84vh !important;
  padding-right: 20px !important;
}

.checkFieldsModal .ant-table {
  min-height: 74vh !important;
  max-height: 74vh !important;
  overflow-y: scroll;
}

.checkFieldsModal-odds .ant-table {
  min-height: 76vh !important;
  max-height: 76vh !important;
}

.checkFieldsModal-playoff .ant-table {
  min-height: 74vh !important;
  max-height: 74vh !important;
}

.checkFieldsModal-odds .editbet .ant-table {
  min-height: auto !important;
  max-height: auto !important;
}

.checkFieldsModal .ant-table-body {
  overflow-y: hidden !important;
}

.ant-notification {
  z-index: 2000 !important;
}

.playerList {
  border: 1px solid #009688;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px 8px 4px 8px;
  background: #fbfbfb;
}

.playerKeys {
  color: #009688;
}

.players {
  color: #204f9d;
  padding-left: 8px;
}

.playersList {
  white-space: "break-spaces";
  display: "flex";
  justify-content: "flex-start";
  font-weight: "bold"
}

.playersList p {
  margin-bottom: 4px;
}

.large-btn {
  padding: 4px;
  color: "grey";
}

.small-table {
  padding: 2px !important
}

.small-table .ant-table-thead>tr>th {
  padding: 2px !important
}

.clrtxt {
  padding: 2px 2px 0px 10px;
  border: 1px solid #cccccc;
  /* background-color: #03056d91; */
  display: flex;
  text-align: center;
  color: '#606060'
}

.drag-card .ant-card-head {
  height: 22px !important;
  padding: 0px;
}

.drag-card .ant-card-body {
  padding: 0px !important;
}

.drag-card {
  margin-top: 4px;
  padding: 0px;
}

.drag-card .ant-card-bordered {
  border: 'none' !important;
}

.ant-drawer-body {
  padding-top: 0px !important;
}

.grag-item {
  padding: 4px;
  margin-bottom: 4px;
  background-color: #03056d91;
  border: 1px solid #cccccc;
}

.ant-switch-checked {
  background-color: #66bb6a !important;
}

/* !!!!!!!!!!!!!!!!!!!!!! Guna codes start here !!!!!!!!!!!!!!!!!!!!!!!!! */

/* >>>>>> global alignment start here <<<<<< */
.anticon-plus svg {
  font-size: 14px;
}

.addbet {
  margin: 0 !important;
}

.p-TB-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.m-B-0 {
  margin-bottom: 0 !important;
}

/* >>>>>> global alignment end here <<<<<< */

/* >>>>>> drawer alignment start here <<<<<< */
.drawer-align-cls {
  margin-top: 25px !important;
}

.drawer-align-cls h5 {
  margin: 40px 0 30px 0 !important;
  font-size: 20px !important;
  overflow: hidden;
}

.drawer-align-cls h5:after {
  content: '';
  width: 100%;
  border-bottom: 1px solid #2222221A;
  display: inline-block;
  vertical-align: middle;
  margin-right: -100%;
  margin-left: 15px;
}

.drawer-align-cls label {
  text-transform: capitalize;
  display: block;
  font-size: 14px;
  color: #2196F3 !important;
  font-weight: bold;
}

.drawer-align-cls .ant-drawer-close svg {
  color: #fff !important;
}

.drawer-align-cls .ant-drawer-close svg:hover {
  color: #000 !important;
}

.drawer-align-cls .ant-select-arrow {
  font-size: 13px !important;
}

.drawer-align-cls .ant-select-arrow .anticon>svg {
  vertical-align: sub !important;
}

.drawer-align-cls .ant-drawer-content-wrapper {
  width: 498px !important;
}

.drawer-align-cls .ant-drawer-header {
  padding: 16px 24px !important;
}

.drawer-align-cls .ant-select-selector,
.drawer-align-cls input {
  border: none !important;
  border-bottom: 1px solid #2196F3 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: none !important;
}

.drawer-align-cls .ant-select,
.drawer-align-cls input {
  margin-bottom: 30px !important;
}

.enablechkboxlabel {
  margin-bottom: 18px !important;
}

.certificatepage {
  margin-top: 0 !important;
}
.certificatepage button {
  height: 36px;
}
.certificatepage input {
  border-radius: 0 !important;
}
.certificatepage .ant-card-body {
  padding: 0 24px !important;
}

.seasonresultcheckbox .ant-checkbox-checked:after {
  border: none !important;
}

.seasonresultcheckbox .ant-checkbox-inner {
  border: 1px solid #1890ff !important;
}

.seasonresultcheckbox {
  position: relative !important;
}

.seasonresultcheckbox h6 {
  position: absolute !important;
  bottom: 17px;
  left: 32px;
}

.drawer-align-cls input {
  color: #424242 !important;
  font-weight: 600 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px !important;
}

.drawer-align-cls .datae {
  color: #424242 !important;
  font-weight: 600 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px !important;
  border: none !important;
}

.drawer-align-cls input:focus {
  box-shadow: none !important;
}

.drawer-align-cls .ant-drawer-body table {
  margin-bottom: 30px !important;
}

.drawer-align-cls span.ant-upload {
  color: #fff !important;
}

.drawer-align-cls .updatebtn {
  margin-top: 54px;
}

.drawer-align-cls .ant-form-item-explain-error {
  position: absolute !important;
  bottom: 28px !important;
}

/* >>>>>> drawer alignment end here <<<<<< */

/* >>>>>> Email-page alignment start here <<<<<< */
.tox-tinymce {
  border: none !important;
  box-shadow: 0 0 5px #3333336e !important;
}

.tox-notifications-container {
  display: none !important;
}

.email-page-design .site-tag-plus {
  margin: 0 15px 15px 0;
  padding: 5px 5px 5px 25px;
  position: relative;
}

.email-page-design .site-tag-plus .anticon-plus {
  position: absolute;
  left: 5px;
  top: 9px;
}

.email-page-design .edit-tag {
  margin: 0 15px 15px 0;
  padding: 5px 25px 5px 5px;
  position: relative;
}

.email-page-design .edit-tag .ant-tag-close-icon {
  position: absolute;
  right: 5px;
  top: 11px;
}

.email-page-design .maininput {
  width: 100%;
  margin-bottom: 15px;
  border: none !important;
  border-bottom: 1px solid #2196F3 !important;
  padding: 0 !important;
  color: #424242 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px !important;
  border-radius: 0 !important;
}

.email-page-design .downloadcsvbtn {
  /* border: none !important; */
}

.email-page-design .downloadcsvbtn img {
  margin-left: 10px !important;
  margin-bottom: 5px !important;
}

.email-page-design .maininput .ant-select-selector {
  border: none !important;
  padding: 0 !important;
}

.email-page-design .maininput:focus {
  box-shadow: none !important;
}

.email-page-design .ant-switch {
  vertical-align: bottom !important;
}

.email-page-design td p:last-child {
  margin-bottom: 0 !important;
}

.email-page-design td {
  vertical-align: middle !important;
}

.email-page-design .tablediv {
  max-height: 300px !important;
  overflow-y: scroll;
}

.email-page-design .input-group {
  position: relative;
}

.email-page-design .input-group .input-group-text {
  position: absolute;
  right: -5px;
  background: #fff;
  border: none;
  bottom: 17px;
  z-index: 1000 !important;
}

.email-page-design .maininput:focus-visible {
  outline: 0 !important;
}

.email-page-design .chooseinput {
  width: 70%;
}

.email-page-design label {
  font-weight: bold;
  color: #0092ff;
  padding: 15px 0 !important;
}

.email-page-design label span {
  color: red;
}

.email-page-design .anticon-plus-circle svg {
  margin: -5px 0 0 3px !important;
}

.email-page-design .addfilebtn {
  font-size: 13px;
}

.email-page-design .sendbtn {
  background: #4caf50;
  position: relative;
}

.email-page-design .sendbtn:hover {
  background: #4caf50;
}

.email-page-design .sendbtn svg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
}

/* >>>>>> Email-page alignment end here <<<<<< */

.clonediv {
  position: relative;
}

.clonediv p {
  position: absolute;
  top: 5px;
  bottom: 0;
  right: 85px;
  color: #098d00;
  margin: auto !important;
  font-weight: bold;
  font-size: 16px;
  display: none;
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dashboard-chart-section .ant-select {
  position: absolute;
  top: 62px;
  z-index: 1000;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}
.dashboard-refesh-icon{
  cursor: pointer !important;
  font-size: 20px !important;
}
.dashboard-refesh-icon:hover {
color: #000;
}


/* !!!!!!!!!!!!!!!!!!!!!! Guna codes end here !!!!!!!!!!!!!!!!!!!!!!!!! */


.landing-page .backbtn {
  cursor: pointer;
  margin-left: 15px;
}
.app-dashboard-page .backbtn {
  cursor: pointer;
  margin-left: 15px;
  margin-bottom: 24px;
}

.form-align-cls .ant-picker {
  width: 100%;
}

.servicepageheader .ant-input {
  width: 217px !important;
}